<template>
  <div class="mx-2 mb-2">
    <div class="row">
      <div class="col-md-12">
        <!-- <b-alert show class="mb-2" variant="danger" v-if="hasRelatedJob">
        <i class="fa fa-check-circle mr-2"></i>
        <span class="sub-title mr-2"
          >
            Item is currently out of stock.
                   
          </span
        >
      </b-alert> -->
      </div>
      <div class="col-lg-6 col-md-12 pr-lg-1 mb-lg-0 mb-2">
        <b-card class="details">
          <div class="header mb-3 d-flex justify-content-between">
            <h5>Employee Information</h5>
            <i class="fa fa-info-circle info-icon text-secondary" id="basicinfo">
              <b-tooltip target="basicinfo" triggers="hover" placement="righttop">
                <div>Employee Details</div>
                <p>Enter Name, Address, Email, Contact Number</p>
              </b-tooltip>
            </i>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">First Name</span>
                <input class="form-control" id="slidepanel-edit-employee-first-name" type="text" v-model="currentDataObj.details.FirstName" @keyup="updateFirstName" />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Last Name</span>
                <input class="form-control" id="slidepanel-edit-employee-last-name" type="text" v-model="currentDataObj.details.LastName" @keyup="updateLastName" />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Address</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-employee-address"
                  type="text"
                  v-model="currentDataObj.details.Address"
                  @keyup="
                    updateField(
                      'Address', //PropertyName
                      currentDataObj.details.Address, //Property Value
                      'Address', //Display Name,
                      currentDataObj.details.Address, //Display Value
                      'Address' //Data object Property Name
                    )
                  "
                />
              </div>

              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">City</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-employee-city"
                  type="text"
                  v-model="currentDataObj.details.City"
                  @keyup="
                    updateField(
                      'City', //PropertyName
                      currentDataObj.details.City, //Property Value
                      'City', //Display Name,
                      currentDataObj.details.City, //Display Value
                      'City' //Data object Property Name
                    )
                  "
                />
              </div>

              <div class="col-md-3 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">State/Province</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-employee-state-province"
                  type="text"
                  v-model="currentDataObj.details.StatProvince"
                  @keyup="
                    updateField(
                      'StatProvince', //PropertyName
                      currentDataObj.details.StatProvince, //Property Value
                      'State / Province', //Display Name,
                      currentDataObj.details.StatProvince, //Display Value
                      'StatProvince' //Data object Property Name
                    )
                  "
                />
              </div>
              <div class="col-md-3 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Zip/Postal</span>
                <input
                  class="form-control"
                  id="slidepanel-edit-employee-zip-postal"
                  type="text"
                  v-model="currentDataObj.details.PostalZip"
                  @keyup="
                    updateField(
                      'PostalZip', //PropertyName
                      currentDataObj.details.PostalZip, //Property Value
                      'Postal / Zip', //Display Name,
                      currentDataObj.details.PostalZip, //Display Value
                      'PostalZip' //Data object Property Name
                    )
                  "
                />
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Country</span>
                <v-select label="name" :options="countries" v-model="selectedCountry" id="slidepanel-edit-employee-country-dropdown" @input="onCountrySelect" :clearable="false"> </v-select>
              </div>
              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Email Address</span>
                <ValidationProvider name="email" rules="email" v-slot="{errors}">
                  <input
                    class="form-control"
                    id="slidepanel-edit-employee-email"
                    type="text"
                    v-model="currentDataObj.details.EmailAddress"
                    @keyup="
                      updateField(
                        'EmailAddress', //PropertyName
                        currentDataObj.details.EmailAddress, //Property Value
                        'Email Address', //Display Name,
                        currentDataObj.details.EmailAddress, //Display Value
                        'EmailAddress' //Data object Property Name
                      )
                    "
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="col-md-6 mb-2">
                <span class="c-toolbar__state-title u-text-capitalize">Mobile Number</span>
                <country-dropdown v-if="selectedCountry" v-model="currentDataObj.details.Mobile" @countrySelect="onCountryDropdownSelect" :country-selected="selectedCountry" @input="updateMobile" />
                <!-- <input class="form-control" type="text" v-mask="phoneMask" v-model="currentDataObj.details.Mobile"
                  @input="formatPhoneNumber('Mobile')"  id="slidepanel-edit-employee-mobile" /> -->
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <div class="col-lg-6 col-md-12 pl-lg-1">
        <b-card class="details">
          <div class="header mb-3 d-flex justify-content-between">
            <h5>Other Information</h5>
            <i class="fa fa-info-circle info-icon text-secondary" id="pcontact">
              <b-tooltip target="pcontact" triggers="hover" placement="righttop">
                <div>Other Information</div>
                <p>additional information about the employee</p>
              </b-tooltip>
            </i>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Department</span>
                    <input
                      class="form-control"
                      id="slidepanel-edit-employee-department"
                      type="text"
                      v-model="currentDataObj.details.Department"
                      @keyup="
                        updateField(
                          'Department', //PropertyName
                          currentDataObj.details.Department, //Property Value
                          'Department', //Display Name,
                          currentDataObj.details.Department, //Display Value
                          'Department' //Data object Property Name
                        )
                      "
                    />
                  </div>

                  <div class="col-md-6 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Hourly Client Rate</span>
                    <div class="input-group input-with-dollar-sign">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        class="form-control"
                        id="slidepanel-edit-employee-client-rate"
                        type="text"
                        v-model="currentDataObj.details.HourlyClientRate"
                        @keyup="
                          updateField(
                            'HourlyClientRate', //PropertyName
                            currentDataObj.details.HourlyClientRate, //Property Value
                            'Hourly Client Rate', //Display Name,
                            currentDataObj.details.HourlyClientRate, //Display Value
                            'HourlyClientRate' //Data object Property Name
                          )
                        "
                      />
                    </div>
                  </div>

                  <div class="col-md-6 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Regular</span>
                    <div class="input-group input-with-dollar-sign">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        class="form-control"
                        id="slidepanel-edit-employee-rate"
                        type="text"
                        v-model="currentDataObj.details.HourlyRate"
                        @keyup="
                          updateField(
                            'HourlyRate', //PropertyName
                            currentDataObj.details.HourlyRate, //Property Value
                            'Hourly Rate', //Display Name,
                            currentDataObj.details.HourlyRate, //Display Value
                            'HourlyRate' //Data object Property Name
                          )
                        "
                      />
                    </div>
                  </div>

                  <div class="col-md-6 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Overtime</span>
                    <div class="input-group input-with-dollar-sign">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        class="form-control"
                        id="slidepanel-edit-employee-overtime"
                        type="text"
                        v-model="currentDataObj.details.OverTime"
                        @keyup="
                          updateField(
                            'OverTime', //PropertyName
                            currentDataObj.details.OverTime, //Property Value
                            'OverTime', //Display Name,
                            currentDataObj.details.OverTime, //Display Value
                            'OverTime' //Data object Property Name
                          )
                        "
                      />
                    </div>
                  </div>

                  <div class="col-md-6 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Premium</span>
                    <div class="input-group input-with-dollar-sign">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        class="form-control"
                        id="slidepanel-edit-employee-premium"
                        type="text"
                        v-model="currentDataObj.details.Premium"
                        @keyup="
                          updateField(
                            'Premium', //PropertyName
                            currentDataObj.details.Premium, //Property Value
                            'Premium', //Display Name,
                            currentDataObj.details.Premium, //Display Value
                            'Premium' //Data object Property Name
                          )
                        "
                      />
                    </div>
                  </div>

                  <div class="col-md-6 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Emergency</span>
                    <div class="input-group input-with-dollar-sign">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        class="form-control"
                        id="slidepanel-edit-employee-emergency"
                        type="text"
                        v-model="currentDataObj.details.Emergency"
                        @keyup="
                          updateField(
                            'Emergency', //PropertyName
                            currentDataObj.details.Emergency, //Property Value
                            'Emergency', //Display Name,
                            currentDataObj.details.Emergency, //Display Value
                            'Emergency' //Data object Property Name
                          )
                        "
                      />
                    </div>
                  </div>

                  <div class="col-md-6 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Travel</span>
                    <div class="input-group input-with-dollar-sign">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        class="form-control"
                        id="slidepanel-edit-employee-travel"
                        type="text"
                        v-model="currentDataObj.details.Travel"
                        @keyup="
                          updateField(
                            'Travel', //PropertyName
                            currentDataObj.details.Travel, //Property Value
                            'Travel', //Display Name,
                            currentDataObj.details.Travel, //Display Value
                            'Travel' //Data object Property Name
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Job Title</span>
                    <v-select
                      :options="UXData.uxRoles"
                      id="slidepanel-edit-client-position"
                      :value="getSelectedDropDownValue('uxRoles', 'IntRoleID')"
                      @input="
                        onDropdownSelectionChange(
                          $event,
                          'Position', //Display Name
                          'uxRoles', //Dropdown List Object
                          'IntRoleID' //Edit Details Property Name
                        )
                      "
                      label="displayName"
                      :clearable="false"
                    >
                    </v-select>
                  </div>

                  <div class="col-md-6 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Commission Rate</span>
                    <div class="input-group input-with-dollar-sign">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        class="form-control"
                        id="slidepanel-edit-employee-commission-rate"
                        type="text"
                        v-model="currentDataObj.details.ComissionRate"
                        @keyup="
                          updateField(
                            'ComissionRate', //PropertyName
                            currentDataObj.details.ComissionRate, //Property Value
                            'Commission Rate', //Display Name,
                            currentDataObj.details.ComissionRate, //Display Value
                            'ComissionRate' //Data object Property Name
                          )
                        "
                      />
                    </div>
                  </div>

                  <div class="col-md-6 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Commission (%)</span>
                    <input
                      class="form-control"
                      id="slidepanel-edit-employee-commission-rate-per"
                      type="text"
                      v-model="currentDataObj.details.CommissionRatePercentage"
                      @keyup="
                        updateField(
                          'CommissionRatePercentage', //PropertyName
                          currentDataObj.details.CommissionRatePercentage, //Property Value
                          'Commission Rate Percentage', //Display Name,
                          currentDataObj.details.CommissionRatePercentage, //Display Value
                          'CommissionRatePercentage' //Data object Property Name
                        )
                      "
                    />
                  </div>

                  <div class="col-md-12 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Total Available Hours (Week)</span>
                    <input
                      class="form-control"
                      id="slidepanel-edit-employee-total-hours-per-week"
                      type="text"
                      v-model="currentDataObj.details.TotalAvailableHoursWeek"
                      @keyup="
                        updateField(
                          'TotalAvailableHoursWeek', //PropertyName
                          currentDataObj.details.TotalAvailableHoursWeek, //Property Value
                          'Total Available Hours per Week', //Display Name,
                          currentDataObj.details.TotalAvailableHoursWeek, //Display Value
                          'TotalAvailableHoursWeek' //Data object Property Name
                        )
                      "
                    />
                  </div>

                  <div class="col-md-12 mb-2">
                    <span class="c-toolbar__state-title u-text-capitalize">Monthly Base Salary</span>
                    <div class="input-group input-with-dollar-sign">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input
                        class="form-control"
                        id="slidepanel-edit-employee-monthly-base-salary"
                        type="text"
                        v-model="currentDataObj.details.MonthlyBaseSalary"
                        @keyup="
                          updateField(
                            'MonthlyBaseSalary', //PropertyName
                            currentDataObj.details.MonthlyBaseSalary, //Property Value
                            'Monthly Base Salary', //Display Name,
                            currentDataObj.details.MonthlyBaseSalary, //Display Value
                            'MonthlyBaseSalary' //Data object Property Name
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  import CountryDropdown from "@/components/_universal/utility/country-dropdown";
  import MODULE from "@/constants/modules";
  import editMixin from "@/mixin/edit-mixin";
  // import GlobalServices from "@/services/global";
  import UtilityString from "@/utilities/strings";
  const countries = require("@/utilities/country-list.json");
  const utility = new UtilityString();
  // let global = new GlobalServices();

  export default {
    mixins: [editMixin],
    data() {
      return {
        isDisabled: false,
        countries: countries,
        selectedCountry: null,
      };
    },
    components: {
      CountryDropdown,
    },
    computed: {
      phoneMask() {
        if (!this.selectedCountry) return "(###) ###-####";
        if (Array.isArray(this.selectedCountry.mask)) {
          return this.selectedCountry.mask[0];
        } else {
          return this.selectedCountry.mask;
        }
      },
      country() {
        if (!this.currentDataObj.details.Country) return;
        return this.countries.find((c) => {
          if (this.currentDataObj.details.Country === "USA") {
            return c.iso === "US";
          } else {
            return c.iso === this.currentDataObj.details.Country;
          }
        });
      },
    },
    created() {
      this.MODULE = MODULE;
    },
    mounted() {
      const defaultCountry = this.countries.find((c) => c.iso === "US");

      if (!this.country) this.selectedCountry = defaultCountry;
      else this.selectedCountry = this.country;
      this.formatPhoneNumber("Mobile");
    },
    methods: {
      async onCountryDropdownSelect(e) {
        this.selectedCountry = e;
        await this.onCountrySelect();
      },
      onCountrySelect(e) {
        this.immediatelyUpdateField(
          "Country", //PropertyName
          this.selectedCountry.iso, //Property Value
          "Country", //Display Name,
          this.selectedCountry.iso, //Display Value
          "Country" //Data object Property Name
        );
        setTimeout(() => {
          this.immediatelyUpdateField(
            "CountryCode", //PropertyName
            this.selectedCountry.code, //Property Value
            "CountryCode", //Display Name,
            this.selectedCountry.code, //Display Value
            "CountryCode" //Data object Property Name
          );
        }, 500);
        this.formatPhoneNumber("Mobile");
      },
      updateMobile() {
        const cleanMobileNumber = utility.removeCharactersForMobile(`${this.selectedCountry.code}${this.currentDataObj.details.Mobile}`);
        this.updateField(
          "Mobile", //PropertyName
          cleanMobileNumber, //Property Value
          "Mobile Number", //Display Name,
          cleanMobileNumber, //Display Value
          "Mobile" //Data object Property Name
        );
      },
      updateFirstName() {
        this.updateField(
          "FirstName", //PropertyName
          this.currentDataObj.details.FirstName, //Property Value
          "First Name", //Display Name,
          this.currentDataObj.details.FirstName, //Display Value
          "FirstName" //Data object Property Name
        );

        setTimeout(() => {
          this.updateFullName();
        }, 500);
      },
      updateLastName() {
        this.updateField(
          "LastName", //PropertyName
          this.currentDataObj.details.LastName, //Property Value
          "Last Name", //Display Name,
          this.currentDataObj.details.LastName, //Display Value
          "LastName" //Data object Property Name
        );

        setTimeout(() => {
          this.updateFullName();
        }, 500);
      },
      updateFullName() {
        this.updateField(
          "FullName", //PropertyName
          `${this.currentDataObj.details.FirstName} ${this.currentDataObj.details.LastName}`, //Property Value
          "FullName", //Display Name,
          `${this.currentDataObj.details.FirstName} ${this.currentDataObj.details.LastName}`, //Display Value
          "FullName" //Data object Property Name
        );
      },
      formatPhoneNumber(propertyName) {
        this.currentDataObj.details[propertyName] = utility.formatPhoneNumber(this.currentDataObj.details[propertyName]);
      },
    },
  };
</script>
